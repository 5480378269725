import styled from "@emotion/styled"

export const PosterSubTitle = styled.h1`
  font-family: "Marcellus";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 34px;
  text-align: center;
  text-transform: capitalize;
  margin: 50px 0;
  display: ${({ mobile }: { mobile: number }) => (!mobile ? "block" : "none")};

  @media (max-width: 768px) {
    font-size: 18px;
    margin: 0;
    display: ${({ mobile }: { mobile: number }) => (mobile ? "block" : "none")};
  }
`
export const ImageContainer = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 50px auto;

  @media (max-width: 768px) {
    margin: 20px auto;
    padding: 0 10%;
  }
`

export const BlogContent = styled.div`
  text-align: center;
  margin: 50px 200px;

  @media (max-width: 768px) {
    margin: 50px 10%;
  }
`
