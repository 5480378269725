import { graphql } from "gatsby"
import React from "react"
import BlogTemplatePage from "../components/blog-template-page"
import Layout from "../components/layout"
import SEO from "../components/seo"
const logo = require("../images/logo.svg").default

const BlogTemplate = props => (
  <Layout
    backgroundColor="white"
    logo={logo}
    menuBGColor="black"
    hamburgerColor="black"
    footerColor="black"
  >
    <SEO title="Rajyashree Ramesh" />
    <BlogTemplatePage data={props.data} />
  </Layout>
)

export default BlogTemplate

export const pageQuery = graphql`
  query getBlog($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        content
        slug
        title
        img {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
            id
          }
        }
      }
    }
  }
`
