import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { PosterTitle } from "../commons/PosterContainer.style"
import { BlogContent, ImageContainer, PosterSubTitle } from "./styled"

const BlogTemplatePage = ({ data }) => (
  <>
    <PosterTitle color="#00000">REFLECTIONS</PosterTitle>
    <PosterSubTitle mobile={0}>
      {data.markdownRemark.frontmatter.title}
    </PosterSubTitle>
    <ImageContainer>
      <GatsbyImage
        image={getImage(data.markdownRemark.frontmatter.img.childImageSharp)}
        alt={data.markdownRemark.frontmatter.title}
      />
    </ImageContainer>
    <PosterSubTitle mobile={1}>
      {data.markdownRemark.frontmatter.title}
    </PosterSubTitle>
    <BlogContent
      dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
    />
  </>
)

export default BlogTemplatePage
